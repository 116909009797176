import React from 'react';


const SSWchooseUs = ({ blocks }) => (
  <section className="ss_wchoose_us spacer_top">
    <div className="container-fluid"> 
      <div className="row justify-content-center"> 
        {blocks.map((block, i) => (
          <div className="col-lg-8 col-md-10">
            <div className="ss_box_bg">
              <div className="row">
                <div className="col-lg-5">
                  <div className="ss_wchoose_one">
                    <h1>{block.heading}</h1>
                    <p>{block.description}</p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="ss_wchoose_two">
                    <img className="img-fluid" src={block.wrapper} alt="wrapper" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default SSWchooseUs;
