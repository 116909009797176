import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Header from 'components/Header';
import Link from 'components/Link';
import SSWchooseUs from 'components/Sections/SSWchooseUs/index';
import SEO from 'components/seo';
import i18nx from 'components/locale/i18nx';


const ServicesPage = ({
  data: {markdownRemark: { frontmatter }},
  pageContext,
}) => {

  return (
    <Layout>
      <SEO
        lang={pageContext.lang}
        title={frontmatter.title}
      />
      <Header lang={pageContext.lang}>
        <div className="ss_breadcrumb text-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{frontmatter.title}</h1>
                <ul>
                  <li><Link to="/">{i18nx('home')}</Link><span> / {frontmatter.title}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Header>
      <SSWchooseUs blocks={frontmatter.blocks} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query Services($lang: String!) {
    markdownRemark(frontmatter: { lang: { eq: $lang }, templateType: { eq: "services"}}) {
      frontmatter {
        title
        blocks {
          description
          heading
          wrapper
        }
      }
    }
  }
`

export default ServicesPage;
